<template>
  <div class="row">
    <div id="map" style="min-height: 650px"></div>
  </div>
</template>

<script>
import GoogleMapsLoader from 'google-maps';

export default {
  name: "PropertiesMap",
  props: ['houseType','houseUrl','iconUrl'],
  data() {
    return {
      markers: [],
      map: null
    }
  },
  methods: {
    initMap() {
      let _this = this;
      GoogleMapsLoader.KEY = 'AIzaSyAf9Ks09fpmf787mWpLz77DhvWcakfFMRk';
      GoogleMapsLoader.VERSION = 'quarterly';
      GoogleMapsLoader.LANGUAGE = 'it';
      GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
      GoogleMapsLoader.load(function (google) {
        _this.google = google;
        var mapOptions = {
          mapId: "dc6e0223d6811c66",
          center: new google.maps.LatLng(45.45428, 9.1621893),
          zoom: 12
        };
        _this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
        axios.get('/api/get-houses/' + _this.houseType).then(function (response) {
          _this.markers = response.data.houses;
          _this.markers.forEach((house,i) => {
            var latLng = new google.maps.LatLng(house.lat,house.lng);
            var m = new google.maps.Marker({
              position: latLng,
              map: _this.map,
              icon: {
                url: _this.iconUrl, // url
                scaledSize: new google.maps.Size(50, 50), // scaled size
                origin: new google.maps.Point(0,0), // origin
                anchor: new google.maps.Point(0, 0) // anchor
              }
            });
            m.addListener("click", () => {
              window.open(_this.houseUrl.replace('X', house.slug));
            });
          });
        });

      })

    },
  },
  mounted() {
    this.initMap();
  }
}
</script>

<style scoped>

</style>