require('../../js/bootstrap');
import Vue from 'vue';
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
    // ...other defaults
});


Vue.component('calendar-booking', require('./components/CalendarBookingHouse.vue').default);
Vue.component('properties-map', require('./components/PropertiesMap.vue').default);

const app = new Vue({
    el: '#app',
});

$(document).ready(function () {
    $(".navbar-collapse .nav-link").click(function () {
        $(".navbar-collapse").toggleClass("open");
        $(".navbar-hamburger .hamburger").toggleClass("active");
    });
});