<template>
  <div>
    <input type="hidden" name="check_in" v-bind:value="check_in">
    <input type="hidden" name="check_out" v-bind:value="check_out">
    <div class="error-danger mb-4" v-html="error"></div>
    <div class="form-floating mb-4">
      <template>
        <vc-date-picker class="inline-block h-full"
                        mode="date"
                        ref="calendar"
                        :min-date='new Date()'
                        :disabled-dates='bookings'
                        is-range
                        @input="updateDatas()"
                        v-model="range">
          <template v-slot="{ inputValue, togglePopover }">
            <div class="d-flex">
              <div class="flex items-center posRelative">
                <svg
                    class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none iconCalendar"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                  <path
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  ></path>
                </svg>
                <input
                    @click="togglePopover()"
                    :value="check_in"
                    placeholder="Check-in"
                    v-bind:class="{ 'form-control-error': guestsError }"
                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500 form-control heightInputCal"
                />
              </div>
              <div class="flex items-center posRelative ml-5">
                <svg
                    class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none iconCalendar"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                  <path
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  ></path>
                </svg>
                <input
                    @click="togglePopover()"
                    :value="check_out"
                    placeholder="Check-out"
                    readonly
                    v-bind:class="{ 'form-control-error': guestsError }"
                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500 form-control heightInputCal"
                />
              </div>
            </div>
            <div v-html="checkInError" class="error-danger"></div>
            <div v-html="checkOutError" class="error-danger"></div>
          </template>
        </vc-date-picker>
      </template>
    </div>
    <div class="form-floating mb-4">
      <div class="form-select-wrapper mb-4">
        <select class="form-select form-control" v-model="selectedGuests"
                v-bind:class="{ 'form-control-error': guestsError }" name="n_ospiti"
                aria-label="Default select example">
          <option selected value="">Numero ospiti</option>
          <option v-bind:value="index" v-for="index in bedsNumber">{{ index }}</option>
        </select>
        <div v-html="guestsError" class="error-danger"></div>
      </div>
    </div>
    <!-- /.form-floating -->
  </div>
</template>

<script>
export default {
  name: "CalendarBookingHouse",
  props: ['houseId', 'checkInOld', 'checkOutOld', 'guestsOld', 'checkInError', 'checkOutError', 'guestsError'],
  data() {
    return {
      selectedGuests: '',
      bedsNumber: null,
      check_in: '',
      check_out: '',
      range: {
        start: '',
        end: '',
      },
      bookings: [],
      error: '',
      existingBooking: ''
    };
  },
  methods: {
    updateDatas() {
      this.check_in = new Date(this.range.start).toLocaleDateString();
      this.check_out = new Date(this.range.end).toLocaleDateString();
      this.CheckYesCheckout()
      if (this.check_in === this.check_out) {
        this.range.start = ''
        this.range.end = ''
        this.check_in = '';
        this.check_out = '';
        this.error = 'Non è possibile selezionare data di check-out uguale a data di check-in'
      } else {
        this.error = ''
      }
    },
    getBookings() {
      axios.get('/api/get-bookings-house-website/' + this.houseId)
          .then(response => {
                this.bedsNumber = response.data.bedsNumber;
                response.data.bookings.forEach(element => {
                  const diffTimeBooking = Math.abs(element.end_d - element.start_d);

                  this.bookings.push({
                    id: element.id,
                    diffTimeBooking: diffTimeBooking,
                    start: new Date(element.start_y, element.start_m, element.start_d + 1),
                    end: new Date(element.end_y, element.end_m, element.end_d - 1)
                  })
                });
                this.bookings.forEach(function (item, index, array) {
                  const diffTime = Math.abs(item.end - array[index + 1].start);
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  console.log(item.id + ' - diffTimeBooking: '+ item.diffTimeBooking + ' - diffDays:' +  diffDays)
                  if (diffDays === 2) {
                    item.end = new Date(item.end.setDate(item.end.getDate() + 1));
                  }
                  if (diffDays === 3 && item.diffTimeBooking === 1) {
                    //console.log(item.id)
                    item.start = new Date(item.start.setDate(item.start.getDate() - 1));
                  }
                });
              }
          )
    },
    CheckYesCheckout() {
      axios.post('/api/check-yes-checkout/' + this.houseId, {check_out: this.check_out})
          .then(response => {
                console.log(response)
                this.existingBooking = response.data.booking
                console.log(this.existingBooking)
                if (this.existingBooking && this.check_in === this.check_out) {
                  console.log('qui')
                  this.range.start = ''
                  this.range.end = ''
                  this.check_in = '';
                  this.check_out = '';
                  this.error = 'Nella data selezionata è possibile effettuare solo check-out'
                }
              }
          )
    }
  },
  mounted() {
    this.getBookings();
    if (this.checkInOld) {
      this.check_in = this.checkInOld;
    }
    if (this.checkOutOld) {
      this.check_out = this.checkOutOld;
    }
    if (this.guestsOld) {
      this.selectedGuests = this.guestsOld;
    }

  }
}
</script>

<style scoped>

</style>